<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row :class="isShow ? 'ui-form__row ui-show' : 'ui-form__row'">
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="会员手机号" name="phone">
						<a-input placeholder="请输入" v-model:value="formState.phone"/>
					</a-form-item>

				</a-row>

				<a-row v-show="isShow">
					<a-form-item class="ui-form__item" label="年卡卡号" name="cardNumber">
						<a-input placeholder="请输入" v-model:value="formState.cardNumber"/>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
              <a-select-option :value="3">PC购票</a-select-option>
						</a-select>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="支付编号" name="outTradeNo">
						<a-input placeholder="请输入" v-model:value="formState.outTradeNo"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
						<a-input placeholder="请输入" v-model:value="formState.nickname"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="交易时间">
						<a-range-picker v-model:value="tradTime" />
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单编号" name="orderNo">
						<a-input placeholder="请输入" v-model:value="formState.orderNo"></a-input>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['report_yearCard_consume_export']">
							<ExportReport type="yearCardConsume" :searchData="searchData"></ExportReport>
						</span>
						<!-- <a-button v-permission="['report_yearCard_consume_export']" type="primary" @click="onExport()" ghost>导出</a-button> -->
						<span v-if="!isShow" class="ui-showMore" @click="onDisplay">展开<Icon icon="DownOutlined"></Icon></span>
						<span v-else class="ui-showMore" @click="onDisplay">收起<Icon icon="UpOutlined"></Icon></span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2000 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'consumeStatistics'">
							<div>消费前：{{record.balanceBefore}}</div>
							<div>消费后：{{record.balanceAfter}}</div>
							<div>实际消费：{{record.balanceConsume}}</div>
						</template>

						<template v-if="column.key == 'filmTime'">
							<div>{{transDateTime(record.showStartTime)}}</div>
							<div>至</div>
							<div>{{transDateTime(record.showEndTime)}}</div>
						</template>

						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
                <a-tag color="blue" v-if="record.source === 2"> 抖音小程序 </a-tag>
                <a-tag color="yellow" v-if="record.source === 3"> PC购票 </a-tag>
							</div>
						</template>

						<template v-if="column.key == 'tradStatus'">
							<div>{{transTradStatus(record.status)}}</div>
						</template>

						<template v-if="column.key == 'ticketStatus'">
							<div>{{transTicketStatus(record.ticketStatus)}}</div>
						</template>

						<template v-if="column.key == 'consumeTime'">
							<div>{{transDateTime(record.createTime)}}</div>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCardCumsumeReport } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
    import moment from 'moment';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					source: 0,
					couponType: 2,//2.年卡 3.次卡
          saleType:3,
				},
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '年卡卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '订单编号',
					dataIndex: 'orderNo'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				},
				// {
				// 	title: '支付编号',
				// 	dataIndex: 'outTradeNo'
				// },
				{
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'memberPhone'
				}, {
					title: '影片名称',
					dataIndex: 'filmName'
				}, {
					title: '消费总额',
					dataIndex: 'balanceConsume'
				}, {
					title: '座位价格',
					dataIndex: 'seatPrice'
				}, {
					title: '交易状态',
					key: 'tradStatus'
				}, {
					title: '取票状态',
					key: 'ticketStatus'
				}, {
					title: '交易时间',
					key: 'consumeTime'
				}, {
					title: '放映起始/结束时间',
					key: 'filmTime',
					width: '10%',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				searchData: [],
                tradTime: [],
				isShow: false,
				organizationList: [],
				cinemaAllList: [],
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				if(this.tradTime && this.tradTime.length){
					this.searchData.startTime = moment(this.tradTime[0].startOf('day')).unix();
					this.searchData.endTime = moment(this.tradTime[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.tradTime = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {data} = await getCardCumsumeReport(postData);
					this.loading = false;
					this.list = data.list;
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(() => {

				})
			},
			onDisplay(){
				this.isShow = !this.isShow;
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = '';
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			transTradStatus(status){
				const tradStatus = {
					'WAIT_BUYER_PAY': '待支付',
					'SYSTEM_CANCEL': '系统超时取消',
					'CUSTOMER_CANCEL': '用户自行取消',
					'WAIT_SELLER_SEND_GOODS': '待发货',
					'WAIT_CHECK': '待核销',
					'WAIT_CTRADE_FINISHHECK': '已核销',
					'WAIT_BUYER_CONFIRM_GOODS': '待收货',
					'AFTERSALE_FINISH': '交易关闭',
					'CHECKOUT_SUCCESS': '已付款',
					'TRADE_FINISH': '已完成'
				}
				return tradStatus[status];
			},
			transTicketStatus(status){
				const statusObj = {
					1: '未取票',
					2: '取票中',
					3: '已取票'
				}
				return statusObj[status];
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-batch{
		margin-right: 10px;
	}
	.ui-showMore{
        color: #6699ff;
        margin-left: 10px;
		cursor: pointer;
    }
</style>
